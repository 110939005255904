import { t } from "i18next";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { RouteEvent } from "../../features/route/routesHistorySlice";
import { Preferences } from "../../features/users/preference/preferencesSlice";
import UserContext from "../../features/users/userContext";
import { IconAlarmSiren } from "../Icon/Line/AlarmSiren";
import { IconAlert } from "../Icon/Line/Alert";
import { IconArrowLeft } from "../Icon/Line/ArrowLeft";
import { IconArrowRight } from "../Icon/Line/ArrowRight";
import { IconDevices } from "../Icon/Line/Devices";
import { IconEyeSlash } from "../Icon/Line/EyeSlash";
import { IconGasStation } from "../Icon/Line/GasStation";
import { IconHumidity } from "../Icon/Line/Humidity";
import { IconParking } from "../Icon/Line/Parking";
import { IconParkingProtection } from "../Icon/Line/ParkingProtection";
import { Stop } from "../Icon/Line/Stop";
import { StopF } from "../Icon/Line/StopF";
import { IconTemperature } from "../Icon/Line/Temperature";
import { IconTrailerTruck } from "../Icon/Line/TrailerTruck";
import { IconChangeOil } from "../Icon/Solid/ChangeOil";
import { IconDistance } from "../Icon/Solid/Distance";
import { IconDriver } from "../Icon/Solid/Driver";
import { IconEngine } from "../Icon/Solid/Engine";
import { ExternalBattery } from "../Icon/Solid/ExternalBattery";
import { IconKey } from "../Icon/Solid/Key";
import { IconLocator } from "../Icon/Solid/Locator";
import { IconWheelChange } from "../Icon/Solid/WheelChange";
import "./EventDetail.css";

interface EventDetailProps {
  events: RouteEventReduced[];
}

interface RouteEventReduced {
  event: RouteEvent;
  count: number;
}

export const EventDetail: React.FC<EventDetailProps> = ({ events }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [preferencesContext]: [Preferences] = useContext(UserContext);

  const detailsType = (event: RouteEvent) => {
    switch (_.camelCase(event.type.name)) {
      case "carAlarm":
        return (
          <div className="mn-event-detail__icon">
            <IconAlarmSiren color="--global-colors-palette-red" size={24} />
          </div>
        );
      case "lift":
      case "crash":
      case "sos":
      case "antiJammer":
        return (
          <div className="mn-event-detail__icon">
            <IconAlert color="--global-colors-palette-red" size={24} />
          </div>
        );
      case "speedLimit":
        return (
          <div className="mn-event-detail__icon">
            <div
              className={
                event?.value?.speedLimit && event?.value?.speedLimit > 99
                  ? "mn-event-detail-circle_speed_limit mn-event-detail-circle_speed_limit_high"
                  : "mn-event-detail-circle_speed_limit"
              }
            >
              {event?.value?.speedLimit !== undefined
                ? Math.floor(event?.value?.speedLimit)
                : ""}
            </div>
          </div>
        );
      case "tirePressure":
        return (
          <div className="mn-event-detail__icon">
            <IconWheelChange
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "fuelRefilled":
      case "fuelLevelFull":
        return (
          <div className="mn-event-detail__icon">
            <IconGasStation color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "hookedTrailerOn":
      case "hookedTrailerOff":
        return (
          <div className="mn-event-detail__icon">
            <IconTrailerTruck color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "fuelLevelEmpty":
      case "fuelLevelReserve":
        return (
          <div className="mn-event-detail__icon">
            <IconGasStation
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "changeOil":
        return (
          <div className="mn-event-detail__icon">
            <IconChangeOil
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "anomalousOdometerDecrease":
        return (
          <div className="mn-event-detail__icon">
            <IconDistance
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "engineBlock":
        return (
          <div className="mn-event-detail__icon">
            <IconEngine color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "privacyMode":
        return (
          <div className="mn-event-detail__icon">
            <IconEyeSlash color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "locateRequest":
        return (
          <div className="mn-event-detail__icon">
            <IconLocator color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "stop":
        if (preferencesContext.language == "it") {
          return (
            <div className="mn-event-detail__icon">
              <StopF color="--global-colors-palette-blue" size={24} />
            </div>
          );
        } else {
          return (
            <div className="mn-event-detail__icon">
              <Stop color="--global-colors-palette-blue" size={24} />
            </div>
          );
        }
      case "parking":
        return (
          <div className="mn-event-detail__icon">
            <IconParking color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "geofenceIn":
      case "geofenceOut":
      case "parkingProtection":
        return (
          <div className="mn-event-detail__icon">
            <IconParkingProtection
              color="--global-colors-palette-blue"
              size={24}
            />
          </div>
        );
      case "assetLoaded":
      case "assetUnloaded":
        return (
          <div className="mn-event-detail__icon">
            <IconDevices color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "noPower":
        return (
          <div className="mn-event-detail__icon">
            <ExternalBattery color="--global-colors-palette-red" size={24} />
          </div>
        );
      case "driverNotIdentified":
      case "driverIdentified":
      case "driverChanged":
        return (
          <div className="mn-event-detail__icon">
            <IconDriver color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "ignitionKeyOn":
        return (
          <div className="mn-event-detail__icon">
            <IconKey color="--global-colors-palette-blue" size={24} />
          </div>
        );
      case "ignitionKeyOff":
        return (
          <div className="mn-event-detail__icon">
            <IconKey color="--global-colors-palette-red" size={24} />
          </div>
        );
      case "temperatureMinThreshold":
      case "temperatureMaxThreshold":
        return (
          <div className="mn-event-detail__icon">
            <IconTemperature
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "HumidityMinThreshold":
      case "HumidityMaxThreshold":
        return (
          <div className="mn-event-detail__icon">
            <IconHumidity
              color="--global-colors-palette-dark-yellow"
              size={24}
            />
          </div>
        );
      case "start":
      case "end":
        return <></>;
      default:
        return <div className="mn-event-detail__icon"></div>;
    }
  };

  const getArrowPrec = (
    onClickHandler: React.MouseEventHandler<HTMLDivElement> | undefined,
    hasPrev: boolean
  ) => {
    return (
      <div
        className="arrow-carousel arrow-left"
        style={{ display: !hasPrev ? "none" : "inherit" }}
        onClick={onClickHandler}
      >
        <IconArrowLeft size={14} color="--global-colors-sky-dark" />
      </div>
    );
  };

  const getArrowNext = (
    onClickHandler: React.MouseEventHandler<HTMLDivElement> | undefined,
    hasNext: boolean
  ) => {
    return (
      <div
        className="arrow-carousel arrow-right"
        style={{ display: !hasNext ? "none" : "inherit" }}
        onClick={onClickHandler}
      >
        <IconArrowRight size={14} color="--global-colors-sky-dark" />
      </div>
    );
  };

  const getDetail = (eventReduced: RouteEventReduced, index: number) => {
    return (
      <div className="mn-detail" id={`${index}`}>
        <div
          className="mn-event-detail"
          style={{ width: events.length === 1 ? "261px" : "" }}
        >
          <div onClick={prev}>{detailsType(eventReduced.event)}</div>
          <div className="mn-event-detail__event" onClick={next}>
            {eventReduced.count > 1 ? "(" + eventReduced.count + "x)" : ""}
            {t(
              `locationHistory.eventDetails.${_.camelCase(
                eventReduced.event.type.name
              )}`
            )}
          </div>
        </div>
      </div>
    );
  };

  const next = () => {
    setCurrentSlide((index) => index + 1);
  };

  const prev = () => {
    setCurrentSlide((index) => index - 1);
  };

  const updateCurrentSlide = (index: number) => {
    if (currentSlide !== index) {
      setCurrentSlide(index);
    }
  };

  return (
    <React.Fragment>
      {events.length === 1 ? (
        getDetail(events[0], 0)
      ) : (
        <React.Fragment>
          <Carousel
            selectedItem={currentSlide}
            onChange={updateCurrentSlide}
            autoFocus={true}
            showThumbs={false}
            showStatus={false}
            useKeyboardArrows
            renderArrowPrev={(onClickHandler, hasPrev) =>
              getArrowPrec(onClickHandler, hasPrev)
            }
            renderArrowNext={(onClickHandler, hasNext) =>
              getArrowNext(onClickHandler, hasNext)
            }
            className="presentation-mode"
          >
            {events.map((event, index) => {
              return (
                <React.Fragment key={index}>
                  {getDetail(event, index)}
                </React.Fragment>
              );
            })}
          </Carousel>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
