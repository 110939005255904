import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { t } from "i18next";
import _ from "lodash";
import moment from "moment";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useMatch } from "react-router-dom";
import { useAppSelector } from "../../app/hooks";
import { store } from "../../app/store";
import { GTFleetErrorCodes } from "../../config/GTfleetErrorCodes";
import PageContent from "../../layout/PageContent";
import { GasStation } from "../../stories/Icon.line.stories";
import { Card } from "../../ui/Card/Card";
import { MiniCard } from "../../ui/Card/MiniCard";
import { DataLabel } from "../../ui/DataLabel/DataLabel";
import { Dropdown } from "../../ui/Dropdown/Dropdown";
import { Tooltip } from "../../ui/Forms/Tooltip";
import { IconAutomations } from "../../ui/Icon/Line/Automations";
import { IconBuild } from "../../ui/Icon/Line/Build";
import { IconCalendar } from "../../ui/Icon/Line/Calendar";
import { IconDevices } from "../../ui/Icon/Line/Devices";
import { IconDollar } from "../../ui/Icon/Line/Dollar";
import { IconEndPoint } from "../../ui/Icon/Line/EndPoint";
import { IconEuro } from "../../ui/Icon/Line/Euro";
import { IconGasStation } from "../../ui/Icon/Line/GasStation";
import { IconHumidity } from "../../ui/Icon/Line/Humidity";
import { IconKilometers } from "../../ui/Icon/Line/Kilometers";
import { IconLicense } from "../../ui/Icon/Line/License";
import { IconMenuDashboard } from "../../ui/Icon/Line/MenuDashboard";
import { IconMenuRoutes } from "../../ui/Icon/Line/MenuRoutes";
import { IconParking } from "../../ui/Icon/Line/Parking";
import { IconPound } from "../../ui/Icon/Line/Pound";
import { IconStartPoint } from "../../ui/Icon/Line/StartPoint";
import { IconTemperature } from "../../ui/Icon/Line/Temperature";
import { IconTheftProtection } from "../../ui/Icon/Line/TheftProtection";
import { IconTimer } from "../../ui/Icon/Line/Timer";
import { IconTrailerTruck } from "../../ui/Icon/Line/TrailerTruck";
import { IconUtilization } from "../../ui/Icon/Line/Utilization";
import { IconBattery } from "../../ui/Icon/Solid/Battery";
import { IconEngine } from "../../ui/Icon/Solid/Engine";
import { ExternalBattery } from "../../ui/Icon/Solid/ExternalBattery";
import { IconMarker } from "../../ui/Icon/Solid/Marker";
import { IconWheelChange } from "../../ui/Icon/Solid/WheelChange";
import { ModalBody } from "../../ui/Modal/ModalBody";
import { ModalFooter } from "../../ui/Modal/ModalFooter";
import { ModalWrapper } from "../../ui/Modal/ModalWrapper";
import { Toolbar } from "../../ui/Navigation/Toolbar";
import { ActionStatusColumns } from "../../ui/SingleItem/ActionStatusColumns";
import { SingleItemContainer } from "../../ui/SingleItem/SingleItemContainer";
import { LocationMap, PositionProps } from "../../ui/Vehicles/LocationMap";
import { TimeSeriesChartCard } from "../../ui/Vehicles/TimeSeriesChartCard";
import { VehicleSummary } from "../../ui/Vehicles/VehicleSummary";
import { typeOfVehicleValues } from "../../ui/Vehicles/VehicleTypes";
import { VehicleUtilization } from "../../ui/Vehicles/VehicleUtilization";
import { ConvertTimeZone, formatTime } from "../../utils/DateAndTimeUtils";
import { ToastNotification } from "../../utils/ToastNotification";
import {
  convertEuroOnCurrency,
  convertFromVoltageToBatteryLevel,
  formatData,
  formatDateForQueryParams,
  formatMinutes,
  formatTemperature,
  getIllustrationFromVehicleType,
  getPeriodRange,
  getQueryString,
  kmToMiles,
  ltToGal,
  mtToKm,
  numberAnnotation,
} from "../../utils/Utils";
import {
  Beacon,
  beaconsSelectors,
  getBeaconsByEntityIdAsync,
} from "../beacon/beaconSlice";
import { BeaconThreshold } from "../beacon/beaconThresholdSlice";
import {
  commandRequestsSelectors,
  createCommandRequestAsync,
  getCommandsRequestAsync,
  selectCommandRequestsSliceReasonCode,
  selectCommandRequestsSliceStatus,
} from "../commands/commandSlice";
import {
  Contract,
  contractsSelectors,
  getContractAsync,
} from "../contract/contractsSlice";
import { Device, devicesSelectors } from "../device/devicesSlice";
import {
  Deadline,
  deadlinesEmptyState,
  deadlinesSelectors,
  getDeadlinesAsync,
  selectDeadlinesSliceReasonCode,
  selectDeadlinesSliceStatus,
} from "../maintenance-deadline/deadlinesSlice";
import {
  SensorSummary,
  analogicReportSummarySelector,
  getAnalogicReportSummaryAsync,
} from "../report/analogic/analogicReportSummarySlice";
import {
  RefuellingReports,
  getRefuellingReportAsync,
  refuellingReportEmptyState,
  refuellingReportSelectors,
  selectRefuellingReportSliceStatus,
} from "../report/refuelling/refuellingReportSlice";
import {
  VehicleStopSummary,
  getVehicleLastStopsAsync,
  selectVehicleStopsSummarySliceReasonCode,
  selectVehicleStopsSummarySliceStatus,
  vehicleStopsSummarySelectors,
} from "../report/vehicle/vehicleStopsSummarySlice";
import {
  VehicleTravelSummary,
  getVehicleLastTracksAsync,
  selectVehicleTravelsSummarySliceReasonCode,
  selectVehicleTravelsSummarySliceStatus,
  vehicleTravelsSummarySelectors,
} from "../report/vehicle/vehicleTravelsSummarySlice";
import {
  VehicleTravelSummaryUtilization,
  getVehicleUtilizationAsync,
  vehicleUtilizationSelectors,
} from "../report/vehicle/vehicleUtilizationSlice";
import { Preferences } from "../users/preference/preferencesSlice";
import { UserPermissions } from "../users/privilege/privilegesSlice";
import UserContext from "../users/userContext";
import "./VehicleDetails.css";
import {
  InstantPoint,
  VehicleFuelCosts,
  getVehicleFuelCostsAsync,
  selectVehiclesFuelCostsSliceReasonCode,
  selectVehiclesFuelCostsSliceStatus,
  vehiclesFuelCostsEmptyState,
  vehiclesFuelCostsSelectors,
} from "./vehiclesFuelCostsSlice";
import {
  VehicleFuelsConsumption,
  getVehicleFuelsConsumptionAsync,
  selectVehiclesFuelsConsumptionSliceReasonCode,
  selectVehiclesFuelsConsumptionSliceStatus,
  vehiclesFuelsConsumptionEmptyState,
  vehiclesFuelsConsumptionSelectors,
} from "./vehiclesFuelsConsumptionSlice";
import {
  VehicleMaintenanceCosts,
  getVehicleMaintenanceCostsAsync,
  selectVehiclesMaintenanceCostsSliceReasonCode,
  selectVehiclesMaintenanceCostsSliceStatus,
  vehiclesMaintenanceCostsEmptyState,
  vehiclesMaintenanceCostsSelectors,
} from "./vehiclesMaintenaceCostsSlice";
import {
  Vehicle,
  vehiclesEmptyState,
  vehiclesSelectors,
} from "./vehiclesSlice";
import {
  Sensor,
  VehicleStatus,
  getVehicleAsync,
  selectVehiclesStatusSliceReasonCode,
  selectVehiclesStatusSliceStatus,
  vehiclesStatusEmptyState,
  vehiclesStatusSelectors,
} from "./vehiclesStatusSlice";
import { getVehiclesViewAsync } from "./vehiclesStatusViewSlice";
import {
  VehicleView,
  vehiclesViewEmptyState,
  vehiclesViewSelectors,
} from "./vehiclesViewSlice";

interface VehicleDetailsProps {
  permissions: UserPermissions;
}
interface Coordinates {
  x: string;
  y: number;
}
interface Period {
  startPeriod: string;
  endPeriod: string;
}

interface QueryParams {
  [paramName: string]: any;
}

interface DataTextDeadlines {
  dataText: string;
  label: string;
  isExpired: boolean;
  isMaintenance: boolean;
  id?: number;
}

interface UtilizationData {
  movingTime: string;
  idleTime: string;
  movingConsumption: string;
  idleConsumption: string;
  percentage?: number;
}

let commandRequestPolling: NodeJS.Timeout | undefined;
let fetchVehicleAndCommand: (
  vehicleId: number,
  fleetId: number,
  deviceId: number
) => void;
const functionalitiesMapping = new Map<
  string,
  { command: string; status: boolean }
>();
export const VehicleDetails: React.FC<VehicleDetailsProps> = ({
  permissions,
}) => {
  //#region Const
  const navigate = useNavigate();
  dayjs.extend(relativeTime);
  const [preferencesContext]: [Preferences] = useContext(UserContext);
  dayjs.locale(preferencesContext.language ?? "en");

  const vehicleRoute = useMatch(
    "/dashboard/fleets/:fleetId/vehicles/:vehicleId/details"
  );

  const [vehicleId, setVehicleId] = useState(-1);
  const [currentIndex, setCurrentIndex] = useState(-1);

  const [viewModalAction, setViewModalAction] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState<string>("");
  const [descriptionModal, setDescriptionModal] = useState<string>("");
  let [commandName, setCommandName] = useState<string>("");
  let [newCommandType, setNewCommandType] = useState<string>("");
  const [isDisableConfirmationModal, setIsDisableConfirmationModal] =
    useState<boolean>(false);

  const [vehiclePosition, setVehiclePosition] = useState<PositionProps>();

  const [dataTextsDeadlines, setDataTextsDeadlines] = useState<
    DataTextDeadlines[]
  >([]);

  let [utilizationData, setUtilizationData] = useState<UtilizationData>();

  const [utilizationPeriod, setUtilizationPeriod] = useState<string[]>([
    dayjs()
      .subtract(1, "week")
      .startOf("week")
      .set("hour", 0)
      .set("minute", 0)
      .format("YYYY-MM-DDTHH:mm"),
    dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DDTHH:mm"),
  ]);
  const [periodFuelConsumption, setPeriodFuelConsumption] = useState({
    startPeriod: new Date(
      new Date(new Date().setDate(new Date().getDate() - 6)).setUTCHours(
        0,
        0,
        0,
        0
      )
    ).toISOString(),
    endPeriod: new Date(
      new Date(new Date().setUTCHours(23, 59, 59, 999))
    ).toISOString(),
  });
  const [periodFuelCosts, setPeriodFuelCosts] = useState({
    startPeriod: new Date(
      new Date(new Date().setDate(new Date().getDate() - 6)).setUTCHours(
        0,
        0,
        0,
        0
      )
    ).toISOString(),
    endPeriod: new Date(
      new Date(new Date().setUTCHours(23, 59, 59, 999))
    ).toISOString(),
  });

  //#endregion Const

  //#region Data
  const vehicles: VehicleView[] = useAppSelector(
    vehiclesViewSelectors.selectAll
  );

  const beacons: Beacon[] = useAppSelector(beaconsSelectors.selectAll);

  const vehicleTravelsSummary: VehicleTravelSummary[] = useAppSelector(
    vehicleTravelsSummarySelectors.selectAll
  );

  const vehicleStopsSummary: VehicleStopSummary[] = useAppSelector(
    vehicleStopsSummarySelectors.selectAll
  );

  const refuellingReportSliceStatus = useAppSelector(
    selectRefuellingReportSliceStatus
  );

  const refuellingReport: RefuellingReports[] = useAppSelector(
    refuellingReportSelectors.selectAll
  );

  let vehicleFuelCosts: VehicleFuelCosts =
    useAppSelector(vehiclesFuelCostsSelectors.selectAll)[0] ??
    ({} as VehicleFuelCosts);

  const vehicleMaintenanceCosts: VehicleMaintenanceCosts =
    useAppSelector(vehiclesMaintenanceCostsSelectors.selectAll)[0] ??
    ({} as VehicleMaintenanceCosts);

  let vehicleFuelsConsumption: VehicleFuelsConsumption =
    useAppSelector(vehiclesFuelsConsumptionSelectors.selectAll)[0] ??
    ({} as VehicleFuelsConsumption);

  const deadlines: Deadline[] = useAppSelector(deadlinesSelectors.selectAll);

  const vehicleUtilization: VehicleTravelSummaryUtilization[] = useAppSelector(
    vehicleUtilizationSelectors.selectAll
  );

  let vehicle: Vehicle =
    useAppSelector((state) => vehiclesSelectors.selectById(state, vehicleId)) ??
    ({} as Vehicle);
  let vehicleStatus: VehicleStatus =
    useAppSelector((state) =>
      vehiclesStatusSelectors.selectById(state, vehicle.vehicleStatus)
    ) ?? ({} as VehicleStatus);
  let device: Device =
    useAppSelector((state) =>
      devicesSelectors.selectById(state, vehicle.device)
    ) ?? ({} as Device);
  document.title = useMemo(() => {
    if (!!vehicle?.alias && !!vehicle?.plate) {
      return (
        `${vehicle.alias} - ${vehicle.plate} - ` +
        t("navigation.adminMenu.vehicles") +
        " - Admin"
      );
    } else {
      return t("navigation.adminMenu.vehicles") + " - Admin";
    }
  }, [vehicle]);
  //#endregion Data

  let initialKms =
    vehicle && vehicle.initialKms && vehicle.initialKms != undefined
      ? vehicle.initialKms
      : 0;

  //#region Status
  const vehicleTravelsSummarySliceStatus = useAppSelector(
    selectVehicleTravelsSummarySliceStatus
  );
  const vehicleTravelsSummarySliceReasonCode = useAppSelector(
    selectVehicleTravelsSummarySliceReasonCode
  );
  const vehicleStopsSummarySliceStatus = useAppSelector(
    selectVehicleStopsSummarySliceStatus
  );
  const vehicleStopsSummarySliceReasonCode = useAppSelector(
    selectVehicleStopsSummarySliceReasonCode
  );

  const vehicleFuelsConsumptionSliceStatus = useAppSelector(
    selectVehiclesFuelsConsumptionSliceStatus
  );

  const vehicleFuelsConsumptionSliceReasonCode = useAppSelector(
    selectVehiclesFuelsConsumptionSliceReasonCode
  );

  const vehicleFuelCostsSliceStatus = useAppSelector(
    selectVehiclesFuelCostsSliceStatus
  );

  const vehicleFuelCostsSliceReasonCode = useAppSelector(
    selectVehiclesFuelCostsSliceReasonCode
  );

  const vehicleMaintenanceCostsSliceStatus = useAppSelector(
    selectVehiclesMaintenanceCostsSliceStatus
  );

  const vehicleMaintenanceCostsSliceReasonCode = useAppSelector(
    selectVehiclesMaintenanceCostsSliceReasonCode
  );

  const commandRequestSliceStatus = useAppSelector(
    selectCommandRequestsSliceStatus
  );
  const commandRequestSliceReasonCode = useAppSelector(
    selectCommandRequestsSliceReasonCode
  );

  const vehicleStatusSliceStatus = useAppSelector(
    selectVehiclesStatusSliceStatus
  );
  const vehicleStatusSliceReasonCode = useAppSelector(
    selectVehiclesStatusSliceReasonCode
  );

  const deadlinesSliceStatus = useAppSelector(selectDeadlinesSliceStatus);

  const deadlinesSliceReasonCode = useAppSelector(
    selectDeadlinesSliceReasonCode
  );
  //#endregion Status
  const [sensorStatusSelected, setSensorStatusSelected] = useState<any>();
  const sensors: Sensor[] =
    (vehicleStatus &&
      vehicleStatus.dynamicFields &&
      vehicleStatus.dynamicFields.sensors) ??
    ([] as Sensor[]);

  const optionsSensor = !_.isEmpty(sensors)
    ? sensors
        .filter((sensor) => sensor.name && sensor.beaconNamespace)
        .map((sensor) => ({
          label: sensor.name,
          key: sensor.beaconNamespace,
        }))
    : [];

  const [sensorPeriodSelected, setSensorPeriodSelected] = useState<any>();
  const [sensorAnalysisData, setSensorAnalysisData] = useState<SensorSummary>();
  const [sensorAnalysisSelected, setSensorAnalysisSelected] = useState<any>();
  const [sensorTemperature, setSensorTemperature] = useState<number>();
  const [sensorHumidity, setSensorHumidity] = useState<number>();
  const [sensorThresholdSelected, setSensorThresholdSelected] =
    useState<BeaconThreshold>();
  const [sensorBatteryLevel, setSensorBatteryLevel] = useState<number>();

  const reportArchiveSummaries: SensorSummary[] = _.orderBy(
    useAppSelector(analogicReportSummarySelector.selectAll),
    "date",
    "asc"
  );

  const optionPeriod = [
    { label: t("common.today"), key: "TODAY" },
    { label: t("common.yesterday"), key: "YESTERDAY" },
  ];

  useEffect(() => {
    if (sensors != null && !_.isEmpty(sensors)) {
      setSensorStatusSelected({
        label: sensors[0].name,
        key: sensors[0].beaconNamespace,
      });
      setSensorAnalysisSelected({
        label: sensors[0].name,
        key: sensors[0].beaconNamespace,
      });
      setSensorPeriodSelected({ label: t("common.today"), key: "TODAY" });
    }
  }, [sensors]);

  useEffect(() => {
    if (!_.isEmpty(sensors) && sensorStatusSelected) {
      sensors.map((el) => {
        if (_.isEqual(el.name, sensorStatusSelected.label)) {
          setSensorTemperature(el.temperature);
          setSensorHumidity(el.humidity);
          setSensorBatteryLevel(el.batteryVoltage);
        }
      });
    }
  }, [sensorStatusSelected]);

  useEffect(() => {
    if (reportArchiveSummaries != null && !_.isEmpty(reportArchiveSummaries)) {
      setSensorAnalysisData(reportArchiveSummaries[0]);
    }
  }, [reportArchiveSummaries]);

  useEffect(() => {
    if (
      !_.isEmpty(optionsSensor) &&
      !_.isEmpty(sensors) &&
      sensorPeriodSelected &&
      sensorAnalysisSelected &&
      vehicleId !== -1
    ) {
      const { startPeriod, endPeriod } = getPeriodRange(
        sensorPeriodSelected.key
      );

      if (startPeriod != null && endPeriod != null) {
        let params =
          "?startPeriod=" +
          formatDateForQueryParams(startPeriod) +
          "&endPeriod=" +
          formatDateForQueryParams(endPeriod);
        store
          .dispatch(
            getAnalogicReportSummaryAsync({
              beaconNamespace: sensorAnalysisSelected.key,
              entityId: vehicleId.toString(),
              queryParam: params,
            })
          )
          .then((response) => {
            if (
              response != null &&
              response.meta.requestStatus != null &&
              response.meta.requestStatus === "rejected"
            ) {
              ToastNotification({
                toastId: "networkError",
                status: "error",
                description: t(
                  "pages.dashboardVehicles.vehicleDetails.sensor.errorToast"
                ),
              });
            }
          });

        store.dispatch(
          getBeaconsByEntityIdAsync({
            entityId: vehicleId,
            queryParams: "?entityType=VEHICLE" + "&type=EYE_SENSOR",
          })
        );
      }
    }
  }, [sensorAnalysisSelected, sensorPeriodSelected]);

  useEffect(() => {
    if (beacons && !_.isEmpty(beacons) && sensorAnalysisSelected) {
      setSensorThresholdSelected(
        beacons.find((el) =>
          _.isEqual(el.namespace, sensorAnalysisSelected.key)
        )?.beaconThreshold
      );
    }
  }, [sensorAnalysisSelected, beacons]);

  //#region Api
  useEffect(() => {
    document.title = t("navigation.userMenu.vehicles");
    store.dispatch(getVehiclesViewAsync(""));
    return function cleanup() {
      cleanUp();
      store.dispatch(deadlinesEmptyState());
      store.dispatch(vehiclesFuelCostsEmptyState());
      store.dispatch(vehiclesFuelsConsumptionEmptyState());
      store.dispatch(vehiclesStatusEmptyState());
      store.dispatch(vehiclesEmptyState());
      store.dispatch(vehiclesViewEmptyState());
      reactDeployTarget && store.dispatch(refuellingReportEmptyState());
      store.dispatch(vehiclesMaintenanceCostsEmptyState());
    };
  }, []);

  function cleanUp() {
    clearTimeout(commandRequestPolling);
    commandRequestPolling = undefined;
    setVehiclePosition(undefined);
  }

  // vehicle, last track, last stop, refuelling, deadline
  useEffect(() => {
    if (vehicleRoute !== null) {
      if (vehicleRoute.params.vehicleId && vehicleRoute.params.fleetId) {
        if (vehicleId !== Number(vehicleRoute.params.vehicleId)) {
          const vehicleId = Number(vehicleRoute.params.vehicleId);
          setVehicleId(vehicleId);
          store.dispatch(
            getVehicleAsync({
              fleetId: Number(vehicleRoute.params.fleetId),
              id: vehicleId,
            })
          );
          const endPeriod = dayjs().format("YYYY-MM-DDTHH:mm");
          const startPeriodDate = new Date(
            dayjs().subtract(7, "day").format("YYYY-MM-DDTHH:mm")
          );
          startPeriodDate.setHours(0, 0, 0, 0);
          const startPeriod = dayjs(startPeriodDate).format("YYYY-MM-DDTHH:mm");
          store.dispatch(
            getVehicleLastTracksAsync({
              vehicleId: vehicleId,
              queryParams: getQueryString({
                "vehicle.id": vehicleId,
                startPeriod: startPeriod,
                endPeriod: endPeriod,
                routeStateTypeEnum: "TRACK",
              }),
            })
          );
          store.dispatch(
            getVehicleLastStopsAsync({
              vehicleId: vehicleId,
              queryParams: getQueryString({
                "vehicle.id": vehicleId,
                startPeriod: startPeriod,
                endPeriod: endPeriod,
                routeStateTypeEnum: "STOP",
              }),
            })
          );

          store.dispatch(
            getRefuellingReportAsync({
              vehicleId: vehicleId,
              queryParams: getQueryString({
                startPeriod: startPeriod,
                endPeriod: endPeriod,
              }),
            })
          );
          if (permissions.deadlines.read) {
            store.dispatch(
              getDeadlinesAsync({
                queryParams: getQueryString({
                  "vehicle.id": vehicleId,
                  size: 10000,
                }),
              })
            );
          }
          return;
        }
      }
    }
    ToastNotification({
      toastId: "unauthorizedError",
      status: "error",
      description: t("common.unauthorizedError"),
    });
    navigate("/dashboard/vehicles");
  }, [navigate, vehicleRoute]);

  // fuel consumption
  useEffect(() => {
    if (vehicleId !== -1) {
      store.dispatch(
        getVehicleFuelsConsumptionAsync({
          queryParams: getQueryString({
            ...periodFuelConsumption,
            routeType: "TRACK",
          } as QueryParams),
          vehicleId: vehicleId,
        })
      );
    }
  }, [periodFuelConsumption, vehicleId]);

  // vehicle fuel, maintenance cost
  useEffect(() => {
    if (vehicleId !== -1) {
      store.dispatch(
        getVehicleFuelCostsAsync({
          queryParams: getQueryString({
            ...periodFuelCosts,
            routeType: "TRACK",
          } as QueryParams),
          vehicleId: vehicleId,
        })
      );
      store.dispatch(
        getVehicleMaintenanceCostsAsync({
          queryParams: getQueryString({
            ...periodFuelCosts,
          } as QueryParams),
          vehicleId: vehicleId,
        })
      );
    }
  }, [periodFuelCosts, vehicleId]);

  // command, contract
  useEffect(() => {
    if (!_.isEmpty(vehicle) && !commandRequestPolling) {
      fetchVehicleAndCommand(vehicle.id, vehicle.fleet, vehicle.device);
      store.dispatch(getContractAsync({ deviceId: vehicle.device }));
    }
  }, [vehicle]);

  // vehicle Utilization
  useEffect(() => {
    let utilizationQueryParams: QueryParams = {};
    utilizationQueryParams["startPeriod"] = utilizationPeriod[0];
    utilizationQueryParams["endPeriod"] = utilizationPeriod[1];
    if (vehicleId !== -1) {
      store.dispatch(
        getVehicleUtilizationAsync({
          vehicleId: vehicleId,
          queryParams: getQueryString(utilizationQueryParams),
          page: 0,
        })
      );
    }
  }, [utilizationPeriod, vehicleId]);

  useEffect(() => {
    if (vehicleUtilization.length >= 0) {
      let movingTime: number = 0;
      let idleTime: number = 0;
      let movingConsumption: number = 0;
      let idleConsumption: number = 0;
      let percentage: number | undefined;
      let unitOfMeasure: string = preferencesContext.isMetric ? " lt" : " gal";
      vehicleUtilization.forEach((travel: VehicleTravelSummaryUtilization) => {
        movingTime += travel.drivingTime;
        idleTime += travel.idleTime;
        movingConsumption += travel?.movingConsumption ?? 0;
        idleConsumption += travel?.idleConsumption ?? 0;
      });
      percentage = Math.round((movingTime * 100) / (movingTime + idleTime));
      setUtilizationData({
        movingTime: formatTime(movingTime * 1000, preferencesContext.language),
        idleTime: formatTime(idleTime * 1000, preferencesContext.language),
        movingConsumption: preferencesContext.isMetric
          ? numberAnnotation(movingConsumption, 2).toString()
          : formatData(
              movingConsumption,
              ltToGal,
              preferencesContext,
              2
            ).toString() + unitOfMeasure,
        idleConsumption: preferencesContext.isMetric
          ? numberAnnotation(idleConsumption, 2).toString()
          : formatData(
              idleConsumption,
              ltToGal,
              preferencesContext,
              2
            ).toString() + unitOfMeasure,
        percentage: percentage,
      });
    }
  }, [vehicleUtilization]);
  //#endregion Api

  //#region Error
  // Vehicle Fuel Consumption Errors
  useEffect(() => {
    if (
      vehicleFuelsConsumptionSliceStatus === "failed" &&
      vehicleFuelsConsumptionSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      vehicleFuelsConsumptionSliceStatus === "failed" &&
      vehicleFuelsConsumptionSliceReasonCode ===
        GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.serverError"),
      });
    }
  }, [
    vehicleFuelsConsumptionSliceStatus,
    vehicleFuelsConsumptionSliceReasonCode,
  ]);

  // Vehicle Fuel Cost Errors
  useEffect(() => {
    if (
      vehicleFuelCostsSliceStatus === "failed" &&
      vehicleFuelCostsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      vehicleFuelCostsSliceStatus === "failed" &&
      vehicleFuelCostsSliceReasonCode ===
        GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t(
          "pages.dashboardVehicles.vehicleDetails.toastNotification.fuelCost.serverError"
        ),
      });
    }
  }, [vehicleFuelCostsSliceStatus, vehicleFuelCostsSliceReasonCode]);

  // Vehicle Maintenance Cost Errors
  useEffect(() => {
    if (
      vehicleMaintenanceCostsSliceStatus === "failed" &&
      vehicleMaintenanceCostsSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
    if (
      vehicleMaintenanceCostsSliceStatus === "failed" &&
      vehicleMaintenanceCostsSliceReasonCode ===
        GTFleetErrorCodes.INTERNAL_SERVER_ERROR
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t(
          "pages.dashboardVehicles.vehicleDetails.toastNotification.fuelCost.serverError"
        ),
      });
    }
  }, [
    vehicleMaintenanceCostsSliceStatus,
    vehicleMaintenanceCostsSliceReasonCode,
  ]);

  // Vehicle Status Errors
  useEffect(() => {
    if (vehicleStatusSliceStatus === "failed") {
      switch (vehicleStatusSliceStatus) {
        case GTFleetErrorCodes.USER_UNAUTHORIZED:
          ToastNotification({
            toastId: "unauthorizedError",
            status: "error",
            description: t("common.unauthorizedError"),
          });
          break;
        case GTFleetErrorCodes.VEHICLE_STATUS_NOT_FOUND:
          ToastNotification({
            toastId: "vehicleNotFoundError",
            status: "error",
            description: t("common.vehicleNotFoundError"),
          });
          break;
        default:
          ToastNotification({
            toastId: "networkError",
            status: "error",
            description: t("common.networkError"),
          });
          break;
      }
      navigate("/dashboard/vehicles");
    }
  }, [vehicleStatusSliceStatus, vehicleStatusSliceReasonCode, navigate]);

  // Deadline Errors
  useEffect(() => {
    if (deadlinesSliceStatus === "failed" && deadlinesSliceReasonCode === "") {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [deadlinesSliceStatus, deadlinesSliceReasonCode]);

  // Vehicle Travel Errors
  useEffect(() => {
    if (
      vehicleTravelsSummarySliceStatus === "failed" &&
      vehicleTravelsSummarySliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleTravelsSummarySliceStatus, vehicleTravelsSummarySliceReasonCode]);

  // Vehicle Stop Errors
  useEffect(() => {
    if (
      vehicleStopsSummarySliceStatus === "failed" &&
      vehicleStopsSummarySliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    }
  }, [vehicleStopsSummarySliceStatus, vehicleStopsSummarySliceReasonCode]);

  // Command Errors
  useEffect(() => {
    if (
      commandRequestSliceStatus === "failed" &&
      commandRequestSliceReasonCode === ""
    ) {
      ToastNotification({
        toastId: "networkError",
        status: "error",
        description: t("common.networkError"),
      });
    } else if (
      commandRequestSliceStatus === "failed" &&
      commandRequestSliceReasonCode ===
        GTFleetErrorCodes.COMMAND_REQUESTS_NOT_FOUND
    ) {
      ToastNotification({
        toastId: "commandRequestNotFoundError",
        status: "error",
        description: t(
          "pages.dashboardVehicles.vehicleDetails.toastNotification.quickActions.commandRequestNotFoundError"
        ),
      });
    } else if (
      commandRequestSliceStatus === "failed" &&
      commandRequestSliceReasonCode === GTFleetErrorCodes.DEVICE_NOT_FOUND
    ) {
      ToastNotification({
        toastId: "commandDeviceNotFoundError",
        status: "error",
        description: t(
          "pages.dashboardVehicles.vehicleDetails.toastNotification.quickActions.commandDeviceNotFoundError"
        ),
      });
    }
  }, [commandRequestSliceStatus, commandRequestSliceReasonCode]);
  //#endregion Error

  //#region Navigation
  useEffect(() => {
    let index: number = vehicles.findIndex(
      (element) => element.id === vehicleId
    );
    index = index !== -1 ? index : 1;
    if (currentIndex !== index) {
      setCurrentIndex(index);
    }
  }, [vehicleId, vehicles]);

  const isLoading =
    vehicleStatusSliceStatus === "loading" ||
    deadlinesSliceStatus === "loading" ||
    vehicleTravelsSummarySliceStatus === "loading" ||
    vehicleFuelsConsumptionSliceStatus === "loading" ||
    vehicleStopsSummarySliceStatus === "loading";

  const handleNext = () => {
    if (vehicles[currentIndex + 1]) {
      navigate(
        "/dashboard/fleets/" +
          vehicles[currentIndex + 1].fleet +
          "/vehicles/" +
          vehicles[currentIndex + 1].id +
          "/details"
      );
      cleanUp();
    }
  };

  const handlePrev = () => {
    if (vehicles[currentIndex - 1]) {
      navigate(
        "/dashboard/fleets/" +
          vehicles[currentIndex - 1].fleet +
          "/vehicles/" +
          vehicles[currentIndex - 1].id +
          "/details"
      );
      cleanUp();
    }
  };
  //#endregion Navigation

  //#region Command

  const contract: Contract =
    useAppSelector((state) =>
      contractsSelectors.selectById(state, vehicle?.contract)
    ) ?? ({} as Contract);
  const options =
    contract?.options
      ?.filter(
        (option) => option.command && functionalitiesMapping.has(option.name)
      )
      .map((option) => {
        return {
          ...functionalitiesMapping.get(option.name)!,
          service: option.enabled,
        };
      }) ?? [];

  fetchVehicleAndCommand = (
    vehicleId: number,
    fleetId: number,
    deviceId: number
  ) => {
    store.dispatch(getCommandsRequestAsync({ deviceId: deviceId }));
    store.dispatch(
      getVehicleAsync({
        fleetId: fleetId,
        id: vehicleId,
      })
    );
    commandRequestPolling = setTimeout(
      () => fetchVehicleAndCommand(vehicleId, fleetId, deviceId),
      60000
    );
  };
  //#endregion Command

  //#region Deadlines-Maintenaces

  const buildString = (deadline: Deadline) => {
    const diffDistance = deadline.currentOdometer - deadline.expirationMeters;
    const diffUtilization =
      deadline.expirationUtilizationMinutes -
      deadline.currentUtilizationMinutes;
    if (deadline.expirationType && deadline.expirationType.includes("KM")) {
      return (
        diffDistance.toLocaleString(preferencesContext.language) +
        (preferencesContext.isMetric ? " km" : " mil")
      );
    }
    if (deadline.expirationType && deadline.expirationType.includes("TIME")) {
      return dayjs().to(dayjs(deadline.expirationDate));
    }
    if (deadline.expirationType && deadline.expirationType.includes("BOTH")) {
      return (
        dayjs().to(dayjs(deadline.expirationDate)) +
        (diffDistance != null &&
          t("common.orBetween") +
            (diffDistance.toLocaleString(preferencesContext.language) +
              (preferencesContext.isMetric ? " km" : " mi")))
      );
    }
    if (
      deadline.expirationType &&
      deadline.expirationType.includes("UTILIZATION_AND_DATE")
    ) {
      if (diffUtilization !== null)
        if (diffUtilization <= 0) {
          return (
            dayjs().to(dayjs(deadline.expirationDate)) +
            t("maintenance.expired.orExpiredFrom") +
            diffUtilization * -1 +
            " h"
          );
        } else {
          return (
            dayjs().to(dayjs(deadline.expirationDate)) +
            (diffUtilization != null &&
              t("common.orBetween") + diffUtilization + " h")
          );
        }
    } else {
      return diffUtilization < 0
        ? t("maintenance.expired.ExpiredFrom") + diffUtilization * -1 + " h"
        : t("common.between") + diffUtilization + " h";
    }
  };

  useEffect(() => {
    if (dataTextsDeadlines.length) {
      setDataTextsDeadlines([]);
    }
    if (deadlines && deadlines.length > 0) {
      deadlines.forEach((deadline: Deadline) => {
        if (
          (!deadline.complete && deadline.status === "EXPIRED") ||
          deadline.status === "EXPIRING"
        ) {
          setDataTextsDeadlines((prev) => [
            ...prev,
            {
              dataText: buildString(deadline) ?? "",
              label: t(
                "pages.dashboardVehicles.vehicleDetails." + deadline.type
              ),
              isExpired: deadline.status === "EXPIRED",
              isMaintenance: deadline.isMaintenance,
              id: deadline.id,
            },
          ]);
        }
      });
    }
  }, [deadlines]);
  //#endregion Deadlines-Maintenaces

  //#region Render
  // update current vehicle position
  useEffect(() => {
    if (!_.isEmpty(vehicleStatus?.dynamicFields)) {
      functionalitiesMapping.set("ENGINE_LOCK", {
        command: "ENGINE_LOCK",
        status: vehicleStatus.dynamicFields.engineLock,
      });
      functionalitiesMapping.set("SERVICE", {
        command: "SERVICE",
        status: vehicle.maintenance,
      });
      functionalitiesMapping.set("LOCATE", {
        command: "LOCATE",
        status: false,
      });
      const time =
        vehicleStatus?.dynamicFields?.lastUpdate ||
        vehicleStatus.dynamicFields?.lastServerUpdate
          ? ConvertTimeZone(
              new Date(
                vehicleStatus?.dynamicFields?.lastUpdate
                  ? vehicleStatus.dynamicFields?.lastUpdate
                  : vehicleStatus?.dynamicFields?.lastServerUpdate
              ),
              preferencesContext.timeZone,
              preferencesContext.localeFormat
            ).replace(",", " |")
          : t("common.na");
      if (
        vehicleStatus.dynamicFields?.latitude &&
        vehicleStatus.dynamicFields?.longitude
      ) {
        setVehiclePosition({
          lat: vehicleStatus.dynamicFields?.latitude,
          long: vehicleStatus.dynamicFields?.longitude,
          location: vehicleStatus.dynamicFields?.address ?? t("common.na"),
          time: time,
          vehicleStatus: vehicleStatus.dynamicFields.vehicleStatus,
        });
      }
    }
  }, [vehicleStatus]);

  /**
   * Return translation of command type status
   */
  function getCommandTypeStatus(
    status: "ACTIVE" | "DEACTIVE" | "PENDING" | "FAILED"
  ) {
    switch (status) {
      case "ACTIVE":
        return t(
          "pages.dashboardVehicles.quickActionsCard.commandTypeModalActive"
        );
      case "PENDING":
        return t(
          "pages.dashboardVehicles.quickActionsCard.commandTypeModalInProgress"
        );
      case "FAILED":
        return t(
          "pages.dashboardVehicles.quickActionsCard.commandTypeModalFailed"
        );
      case "DEACTIVE":
      default:
        return t(
          "pages.dashboardVehicles.quickActionsCard.commandTypeModalDeactive"
        );
    }
  }

  function getDetails(vehicle: Vehicle) {
    return [
      {
        icon: <IconMenuDashboard size={14} color="--global-colors-ink-light" />,
        label:
          vehicle.brand && vehicle.model
            ? vehicle.brand + " " + vehicle.model
            : "-",
        description: t("pages.dashboardVehicles.summaryDetails.model"),
      },

      {
        icon: <IconLicense size={14} color="--global-colors-ink-light" />,
        label: vehicle.plate ?? "-",
        description: t("pages.dashboardVehicles.summaryDetails.plate"),
      },
      {
        icon: <IconEngine size={14} color="--global-colors-ink-light" />,
        label: vehicle?.type
          ? t("common.optionsEnum.type." + vehicle.type)
          : "-",
        description: t("pages.dashboardVehicles.summaryDetails.type"),
      },
      {
        icon: <IconDevices size={14} color="--global-colors-ink-light" />,
        label: device.modelName ?? "-",
        description: t("pages.dashboardVehicles.summaryDetails.device"),
      },
    ];
  }

  const getInternalBatteryTrailer = () => {
    let { internalBatteryLevel, internalBatteryPercentage } =
      vehicleStatus.dynamicFields || {};

    if (!internalBatteryLevel && !internalBatteryPercentage) return "";

    if (internalBatteryLevel && internalBatteryPercentage) {
      return `${numberAnnotation(
        internalBatteryLevel,
        1
      )} V (${numberAnnotation(internalBatteryPercentage, 1)} %)`;
    }

    return internalBatteryLevel
      ? `${numberAnnotation(internalBatteryLevel, 1)} V`
      : internalBatteryPercentage
      ? `${numberAnnotation(internalBatteryPercentage, 1)} %`
      : "";
  };

  const returnSinglecontainer = useCallback(
    (arrayContainer: DataTextDeadlines[], isMaintenance: boolean) => {
      return arrayContainer.map(
        (dataText: DataTextDeadlines, index: number) => {
          if (dataText.isMaintenance === isMaintenance) {
            let arrayTypeDataText = {} as Deadline;
            if (dataText.id) {
              arrayTypeDataText =
                deadlinesSelectors.selectById(store.getState(), dataText.id) ??
                ({} as Deadline);
            }
            return (
              <SingleItemContainer
                key={index}
                onClick={() => {
                  const vehicle = vehiclesViewSelectors.selectById(
                    store.getState(),
                    arrayTypeDataText.vehicle
                  );
                  if (vehicle) {
                    window
                      .open(
                        `/dashboard/maintenance` +
                          getQueryString({
                            type: [arrayTypeDataText.type],
                            "vehicle.plate": vehicle.plate,
                            "vehicle.alias": vehicle.alias,
                            status: arrayTypeDataText.status,
                          }),
                        "_blank"
                      )
                      ?.focus();
                  }
                }}
                label={t(
                  "pages.dashboardVehicles.vehicleDetails." +
                    (dataText.isExpired ? "expired" : "expiring")
                )}
              >
                <DataLabel
                  className={dataText.isExpired ? "viewRed" : ""}
                  dataText={dataText.dataText}
                  icon={<IconWheelChange size={14} />}
                  label={dataText.label}
                />
              </SingleItemContainer>
            );
          }
          return null;
        }
      );
    },
    []
  );

  /**
   * @todo "if code" will have to be removed when we will have maintenance data.
   * @param instantPoints
   * @param period
   * @returns
   */
  const expandPeriod = (
    instantPoints: InstantPoint[],
    period: Period
  ): Coordinates[] => {
    let currentDate = new Date(period.startPeriod);
    let stopDate = new Date(period.endPeriod);
    stopDate.setDate(stopDate.getDate() + 1);

    const dates: Coordinates[] = new Array();
    while (currentDate <= stopDate) {
      dates.push({ x: currentDate.toISOString(), y: 0 } as Coordinates);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (instantPoints.length) {
      instantPoints.forEach((instantPoint: InstantPoint) => {
        dates.forEach((data) => {
          const dataXDate = new Date(data.x);
          const instantPointDate = new Date(instantPoint.date);
          const dataXDateDay = dataXDate.getDate();
          const dataXDateMonth = dataXDate.getMonth();
          const dataXDateYear = dataXDate.getFullYear();
          const instantPointDateDay = instantPointDate.getDate();
          const instantPointDateMonth = instantPointDate.getMonth();
          const instantPointDateYear = instantPointDate.getFullYear();
          if (
            dataXDateDay === instantPointDateDay &&
            dataXDateMonth === instantPointDateMonth &&
            dataXDateYear === instantPointDateYear
          ) {
            data.y = instantPoint.data;
          }
        });
      });
    }
    return dates;
  };

  const volumetricUom = preferencesContext.isMetric ? "lt" : "gal";
  const metricUom = preferencesContext.isMetric ? "km" : "mil";
  const volumetricFractionalMetricUom = volumetricUom + "/" + metricUom;
  const currency =
    preferencesContext.currency === "EURO"
      ? "€"
      : preferencesContext.currency === "DOLLAR"
      ? "$"
      : "£";
  const currencyFractionalMetricUom = currency + "/" + metricUom;
  const reactDeployTarget =
    process.env.REACT_APP_DEPLOY_TARGET === "stage" ||
    process.env.REACT_APP_DEPLOY_TARGET === "local";

  return (
    <>
      <Toolbar
        name={[vehicle.alias ?? "-", vehicle.plate ?? "-"]}
        current={currentIndex + 1}
        total={vehicles.length}
        handleSettings={() => {
          navigate({
            pathname: `/admin/vehicles/edit/${vehicle.fleet}/${vehicle.id}`,
          });
        }}
        handleSendMsg={() => {}}
        handlePrev={handlePrev}
        handleNext={handleNext}
        isActionPerforming={isLoading}
      />
      <VehicleSummary
        icon={getIllustrationFromVehicleType(vehicle)}
        status={
          vehicleStatus.dynamicFields
            ? vehicleStatus.dynamicFields.vehicleStatus
            : "UNKNOWN"
        }
        onToggle={(open) => {
          if (open) {
            document
              .querySelector(".page-content")
              ?.classList.replace(
                "withVehicleDetails",
                "withVehicleDetailsOpen"
              );
          } else {
            document
              .querySelector(".page-content")
              ?.classList.replace(
                "withVehicleDetailsOpen",
                "withVehicleDetails"
              );
          }
        }}
        details={getDetails(vehicle)}
        accessories={
          contract?.service && contract?.accessories
            ? contract.accessories.map((item) => item.name)
            : []
        }
      />
      <PageContent className="withVehicleDetails">
        <LocationMap position={vehiclePosition} />
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            padding: "24px 0",
            background: "#f7f7f8",
          }}
        >
          {/* Quick Actions ***** Mock Data */}
          <div className="card-flex-vehicle">
            {device?.modelName !== "GT995TNR" ? (
              <Card
                title={t("pages.dashboardVehicles.vehicleDetails.quickActions")}
                titleColumn={
                  options.length > 0
                    ? [
                        t("pages.dashboardVehicles.vehicleDetails.service"),
                        t("pages.dashboardVehicles.vehicleDetails.vehicle"),
                      ]
                    : undefined
                }
              >
                {options.length > 0 ? (
                  options.map((option, index) => {
                    if (option) {
                      const request = commandRequestsSelectors.selectById(
                        store.getState(),
                        option.command
                      );
                      const commandStatus =
                        request?.status === "PENDING" ||
                        request?.status === "FAILED"
                          ? request.status
                          : option.status
                          ? "ACTIVE"
                          : "DEACTIVE";
                      const newCommandTypeModal = option.status
                        ? "DEACTIVE"
                        : "ACTIVE";

                      return (
                        <ActionStatusColumns
                          key={index}
                          clickAction={() => {
                            setCommandName(option.command);
                            setNewCommandType(newCommandTypeModal);
                            setViewModalAction(true);
                            setIsDisableConfirmationModal(
                              request?.status === "PENDING"
                            );
                            setTitleModal(
                              t(
                                "pages.dashboardVehicles.quickActionsCard.title." +
                                  option.command +
                                  "_" +
                                  newCommandTypeModal
                              )
                            );
                            setDescriptionModal(
                              t(
                                "pages.dashboardVehicles.quickActionsCard.description." +
                                  option.command +
                                  "_" +
                                  newCommandTypeModal
                              )
                            );
                          }}
                          serviceType={
                            !option.status
                              ? t(
                                  "pages.dashboardVehicles.quickActionsCard.commandTypeModalActive"
                                )
                              : t(
                                  "pages.dashboardVehicles.quickActionsCard.commandTypeModalDeactive"
                                )
                          }
                          commandType={getCommandTypeStatus(commandStatus)}
                          //todo: that is a temporary lock
                          serviceStatus={option.service ? "ACTIVE" : "DEACTIVE"}
                          vehicleStatus={commandStatus}
                          label={t(
                            "pages.dashboardVehicles.quickActionsCard.label." +
                              option.command
                          )}
                          subLabel={
                            request?.status === "PENDING"
                              ? `1 ${t(
                                  "pages.dashboardVehicles.quickActionsCard.sublabel"
                                )}`
                              : `0 ${t(
                                  "pages.dashboardVehicles.quickActionsCard.sublabel"
                                )}`
                          }
                          disabled={false}
                        />
                      );
                    } else {
                      return null;
                    }
                  })
                ) : (
                  <div className="quick-actions-notFound">
                    {t(
                      "pages.dashboardVehicles.quickActionsCard.noQuickActions"
                    )}
                  </div>
                )}
              </Card>
            ) : (
              <></>
            )}
            {/* Last tracks */}
            <Card
              title={t("pages.dashboardVehicles.vehicleDetails.lastTracks")}
              titleTooltip={t(
                "pages.dashboardVehicles.vehicleDetails.lastTracksTooltip"
              )}
              linkAnchor={t("common.viewLocationHistory")}
              linkAction={() => {
                window.open(
                  "/dashboard/vehicles/location-history/tracks" +
                    getQueryString({
                      firstPointDate: [
                        dayjs(
                          new Date().setDate(new Date().getDate() - 6)
                        ).format("YYYY/MM/DD HH:mm"),
                        dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
                      ],
                      routeStateTypeEnum: "TRACK",
                      "vehicle.id": vehicleId,
                    }),
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {vehicleTravelsSummarySliceStatus === "idle" &&
              vehicleTravelsSummary &&
              vehicleTravelsSummary.length > 0 ? (
                _.orderBy(
                  vehicleTravelsSummary,
                  ["firstPosition.gpsPositionTimestamp"],
                  "desc"
                )
                  .slice(0, 5)
                  .map((route, index) => (
                    <>
                      <Tooltip />
                      <SingleItemContainer
                        key={index}
                        element={route}
                        onClick={(item: VehicleTravelSummary) => {
                          window.open(
                            `/dashboard/vehicles/location-history/track/${item.routeId}` +
                              getQueryString({
                                firstPointDate: [
                                  dayjs(
                                    new Date(
                                      item.firstPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.firstPosition.gpsPositionTimestamp
                                      ).getDate() - 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                  dayjs(
                                    new Date(
                                      item.lastPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.lastPosition.gpsPositionTimestamp
                                      ).getDate() + 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                ],
                                routeStateTypeEnum: "TRACK",
                                "vehicle.id": item.vehicleId ?? "",
                              }),
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <span data-for="tooltip" data-tip={t("common.start")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.firstPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconStartPoint size={14} />}
                          />
                        </span>
                        <span data-for="tooltip" data-tip={t("common.end")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.lastPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconEndPoint size={14} />}
                          />
                        </span>
                        <div
                          style={{
                            minWidth: "90px",
                          }}
                        >
                          <DataLabel
                            dataText={
                              preferencesContext.isMetric
                                ? numberAnnotation(mtToKm(route.traveled)) +
                                  " km"
                                : numberAnnotation(
                                    kmToMiles(mtToKm(route.traveled))
                                  ) + " mil"
                            }
                            icon={<IconMenuRoutes size={14} />}
                          />
                        </div>
                        <div
                          style={{
                            minWidth: "100px",
                          }}
                        >
                          <DataLabel
                            dataText={formatTime(
                              route?.drivingTime * 1000,
                              preferencesContext.language
                            )}
                            icon={<IconTimer size={14} />}
                          />
                        </div>
                        <DataLabel
                          dataText={numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              route?.costs
                            ),
                            2
                          )}
                          icon={
                            preferencesContext.currency === "EURO" ? (
                              <IconEuro size={14} />
                            ) : preferencesContext.currency === "DOLLAR" ? (
                              <IconDollar size={14} />
                            ) : (
                              <IconPound size={14} />
                            )
                          }
                        />
                      </SingleItemContainer>
                    </>
                  ))
              ) : (
                <div className="last-tracks-notFound">
                  {t("pages.dashboardVehicles.vehicleDetails.noTracks")}
                </div>
              )}
            </Card>
            {/* Last stops */}
            <Card
              title={t("pages.dashboardVehicles.vehicleDetails.lastStops")}
              titleTooltip={t(
                "pages.dashboardVehicles.vehicleDetails.lastStopsTooltip"
              )}
              linkAnchor={t("common.viewLocationHistory")}
              linkAction={() => {
                window.open(
                  "/dashboard/vehicles/location-history/tracks" +
                    getQueryString({
                      firstPointDate: [
                        dayjs(
                          new Date().setDate(new Date().getDate() - 6)
                        ).format("YYYY/MM/DD HH:mm"),
                        dayjs(new Date()).format("YYYY/MM/DD HH:mm"),
                      ],
                      routeStateTypeEnum: "STOP",
                      "vehicle.id": vehicleId,
                    }),
                  "_blank",
                  "noopener,noreferrer"
                );
              }}
            >
              {vehicleStopsSummarySliceStatus === "idle" &&
              vehicleStopsSummary &&
              vehicleStopsSummary.length > 0 ? (
                _.orderBy(
                  vehicleStopsSummary,
                  ["firstPosition.gpsPositionTimestamp"],
                  "desc"
                )
                  .slice(0, 5)
                  .map((route, index) => (
                    <>
                      <Tooltip />
                      <SingleItemContainer
                        key={index}
                        element={route}
                        onClick={(item: VehicleStopSummary) => {
                          window.open(
                            `/dashboard/vehicles/location-history/track/${item.routeId}` +
                              getQueryString({
                                firstPointDate: [
                                  dayjs(
                                    new Date(
                                      item.firstPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.firstPosition.gpsPositionTimestamp
                                      ).getDate() - 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                  dayjs(
                                    new Date(
                                      item.lastPosition.gpsPositionTimestamp
                                    ).setDate(
                                      new Date(
                                        item.lastPosition.gpsPositionTimestamp
                                      ).getDate() + 1
                                    )
                                  ).format("YYYY/MM/DD HH:mm"),
                                ],
                                routeStateTypeEnum: "STOP",
                                "vehicle.id": item.vehicleId ?? "",
                              }),
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }}
                      >
                        <span data-for="tooltip" data-tip={t("common.start")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.firstPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconStartPoint size={14} />}
                          />
                        </span>
                        <span data-for="tooltip" data-tip={t("common.end")}>
                          <DataLabel
                            dataText={dayjs(
                              route?.lastPosition.gpsPositionTimestamp
                            ).format("DD MMM HH:mm")}
                            icon={<IconEndPoint size={14} />}
                          />
                        </span>

                        <div>
                          <DataLabel
                            dataText={formatTime(
                              route?.parkingTime * 1000,
                              preferencesContext.language
                            )}
                            icon={<IconTimer size={14} />}
                          />
                        </div>
                        <DataLabel
                          dataText={route?.firstPosition.address}
                          icon={<IconParking size={14} />}
                        />
                      </SingleItemContainer>
                    </>
                  ))
              ) : (
                <div className="last-tracks-notFound">
                  {t("pages.dashboardVehicles.vehicleDetails.noStops")}
                </div>
              )}
            </Card>
            {/* Fuel */}
            {!_.isEmpty(preferencesContext) && (
              <TimeSeriesChartCard
                graphTitle={{
                  first: {
                    title: t("card.fuelConsumption"),
                  },
                }}
                chartDataSeries={[
                  {
                    name: "fuelsConsumption",
                    data: expandPeriod(
                      vehicleFuelsConsumption.fuelsConsumption?.length > 0
                        ? vehicleFuelsConsumption.fuelsConsumption.map(
                            (item) => {
                              return {
                                ...item,
                                data: preferencesContext.isMetric
                                  ? item?.data
                                  : ltToGal(item?.data),
                              };
                            }
                          )
                        : [],
                      periodFuelConsumption
                    ),
                    unitMeasure: volumetricUom,
                  },
                ]}
                chartDateSeries={[
                  {
                    date:
                      vehicleFuelsConsumption.fuelsConsumption?.length > 0
                        ? vehicleFuelsConsumption.fuelsConsumption.map(
                            (fuelConsumption) => fuelConsumption.date
                          )
                        : [
                            periodFuelConsumption.startPeriod,
                            periodFuelConsumption.endPeriod,
                          ],
                  },
                ]}
                firstChartData={{
                  title: t("card.fuelConsumption"),
                  first: {
                    title: t("card.averageConsumption"),
                    data: vehicleFuelsConsumption.summary?.avgConsumption
                      ? numberAnnotation(
                          preferencesContext.isMetric
                            ? vehicleFuelsConsumption.summary.avgConsumption
                            : kmToMiles(
                                vehicleFuelsConsumption.summary.avgConsumption
                              )
                        ) +
                        " " +
                        volumetricFractionalMetricUom
                      : `${t("common.na")}`,
                  },
                  second: reactDeployTarget
                    ? {
                        title: t("card.zeroConsumption"),
                        data: vehicleFuelsConsumption.summary?.zeroConsumption
                          ? numberAnnotation(
                              preferencesContext.isMetric
                                ? vehicleFuelsConsumption.summary
                                    .zeroConsumption
                                : kmToMiles(
                                    vehicleFuelsConsumption.summary
                                      .zeroConsumption
                                  )
                            ) +
                            " " +
                            metricUom
                          : `${t("common.na")}`,
                      }
                    : undefined,
                  third: {
                    title: t("card.totalFuelConsumption"),
                    data: vehicleFuelsConsumption.summary?.totalConsumption
                      ? numberAnnotation(
                          preferencesContext.isMetric
                            ? vehicleFuelsConsumption.summary.totalConsumption
                            : ltToGal(
                                vehicleFuelsConsumption.summary.totalConsumption
                              )
                        ) +
                        " " +
                        volumetricUom
                      : `${t("common.na")}`,
                  },
                }}
                setPeriod={setPeriodFuelConsumption}
                title={t(
                  "pages.dashboardVehicles.vehicleDetails.fuelConsumption"
                )}
                linkAnchor={
                  reactDeployTarget
                    ? t("pages.dashboardVehicles.vehicleDetails.viewFuelReport")
                    : ""
                }
                linkAction={() => {}}
                hasDropdown={true}
                status={
                  vehicleFuelsConsumptionSliceStatus ||
                  vehicleMaintenanceCostsSliceStatus
                }
              />
            )}
            {/* )} */}
          </div>
          <div className="card-flex-vehicle">
            {/* Vehicle Status ***** Mock Data */}
            <Card
              title={t("pages.dashboardVehicles.vehicleDetails.vehicleStatus")}
            >
              {vehicleStatus?.dynamicFields?.odometer ? (
                <MiniCard
                  icon={<IconKilometers size={26} />}
                  preTitle={
                    preferencesContext.isMetric
                      ? `${numberAnnotation(
                          mtToKm(vehicleStatus?.dynamicFields.odometer) +
                            initialKms,
                          1
                        )} km`
                      : `${numberAnnotation(
                          kmToMiles(
                            mtToKm(vehicleStatus?.dynamicFields.odometer) +
                              initialKms
                          ),
                          1
                        )} mil`
                  }
                  title={t("pages.dashboardVehicles.vehicleStatus.traveledKm")}
                />
              ) : (
                <></>
              )}
              {vehicleStatus.dynamicFields?.dataSource ? (
                <MiniCard
                  icon={<IconAutomations size={26} />}
                  preTitle={vehicleStatus.dynamicFields?.dataSource}
                  title={t("pages.dashboardVehicles.vehicleStatus.dataSource")}
                />
              ) : null}
              {vehicleStatus.dynamicFields?.utilizationMinutes &&
              _.isEqual(vehicle.type, typeOfVehicleValues.OPERATING_MACHINE) ? (
                <MiniCard
                  icon={<IconUtilization size={26} />}
                  preTitle={formatMinutes(
                    vehicleStatus.dynamicFields?.utilizationMinutes
                  )}
                  title={t(
                    "pages.dashboardVehicles.vehicleStatus.utilizationMinutes"
                  )}
                />
              ) : null}
              {reactDeployTarget && (
                <MiniCard
                  icon={<IconTimer size={26} />}
                  preTitle="15g 22h 8m"
                  title={t("pages.dashboardVehicles.vehicleStatus.drivingTime")}
                />
              )}
              <MiniCard
                icon={<IconBuild size={26} />}
                title={contract?.service ?? ""}
                status={
                  contract?.service &&
                  moment(contract?.expirationDate).diff(
                    contract?.activationDate,
                    "days"
                  ) > 0
                    ? "success"
                    : "failed"
                }
              />
              {reactDeployTarget && (
                <MiniCard
                  icon={<IconTheftProtection size={26} />}
                  title={t("pages.dashboardVehicles.vehicleStatus.diagnosis")}
                  status="failed"
                />
              )}
              {vehicleStatus.dynamicFields?.fuelLevelLiters ? (
                <MiniCard
                  icon={<GasStation size={26} />}
                  preTitle={
                    preferencesContext.isMetric
                      ? numberAnnotation(
                          vehicleStatus?.dynamicFields?.fuelLevelLiters
                        ) + " lt"
                      : numberAnnotation(
                          ltToGal(vehicleStatus?.dynamicFields?.fuelLevelLiters)
                        ) + " gal"
                  }
                  title={t("pages.dashboardVehicles.vehicleStatus.fuelLevel")}
                />
              ) : null}
              {(_.isEqual(vehicle.type, typeOfVehicleValues.TRAILER_CAR) ||
                _.isEqual(vehicle.type, typeOfVehicleValues.TRAILER)) && (
                <MiniCard
                  icon={<IconTrailerTruck size={26} />}
                  preTitle={
                    vehicleStatus.dynamicFields?.hookedTrailer
                      ? t(
                          "pages.dashboardVehicles.vehicleStatus.hookedTrailer"
                        ) + " "
                      : t(
                          "pages.dashboardVehicles.vehicleStatus.unhookedTrailer"
                        ) + " "
                  }
                  title={t(
                    "pages.dashboardVehicles.vehicleStatus.stateHookedTrailer"
                  )}
                />
              )}
              {vehicleStatus.dynamicFields?.externalBatteryLevel ? (
                <MiniCard
                  icon={<ExternalBattery size={26} />}
                  preTitle={
                    numberAnnotation(
                      vehicleStatus.dynamicFields?.externalBatteryLevel,
                      1
                    ).toString() + " V"
                  }
                  title={t(
                    "pages.dashboardVehicles.vehicleStatus.externalBattery"
                  )}
                />
              ) : null}
              {vehicleStatus.dynamicFields?.internalBatteryLevel ||
              vehicleStatus.dynamicFields?.internalBatteryPercentage ? (
                <div className="internal-battery">
                  <MiniCard
                    icon={<IconBattery size={26} />}
                    preTitle={getInternalBatteryTrailer()}
                    title={t(
                      "pages.dashboardVehicles.vehicleStatus.internalBattery"
                    )}
                  />
                </div>
              ) : null}
            </Card>
            {/*SENSOR*/}
            {!_.isEmpty(optionsSensor) && (
              <Card
                title={t(
                  "pages.dashboardVehicles.vehicleDetails.sensor.statusSensor"
                )}
                titleTooltip={t(
                  "pages.dashboardVehicles.vehicleDetails.sensor.statusSensor"
                )}
                linkAnchor={t(
                  "pages.dashboardVehicles.vehicleDetails.sensor.viewAnalogicReport"
                )}
                linkAction={() => {
                  window.open(
                    "/reports/analogic-report/" +
                      vehicleId +
                      getQueryString({
                        startPeriod: dayjs(
                          new Date().setDate(new Date().getDate() - 6)
                        ).format(),
                        endPeriod: dayjs(new Date()).format(),
                      }),
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                <div className="sensor-dropdown">
                  <Dropdown
                    isClearable={false}
                    placeholder={t(
                      "pages.dashboardVehicles.vehicleDetails.sensor.selectSensor"
                    )}
                    value={sensorStatusSelected}
                    size="normal"
                    itemAttribute="label"
                    onChange={(val) => {
                      setSensorStatusSelected(val[0]);
                    }}
                    options={optionsSensor}
                  />
                </div>
                <MiniCard
                  icon={<IconTemperature size={26} />}
                  preTitle={t(
                    "pages.dashboardVehicles.vehicleDetails.sensor.temperature"
                  )}
                  title={
                    sensorTemperature
                      ? `${formatTemperature(sensorTemperature)}`
                      : "--"
                  }
                />
                <MiniCard
                  icon={<IconHumidity size={26} />}
                  preTitle={t(
                    "pages.dashboardVehicles.vehicleDetails.sensor.humidity"
                  )}
                  title={
                    sensorHumidity
                      ? `${numberAnnotation(sensorHumidity, 0, 0)}${"%"}`
                      : "--"
                  }
                />
                <MiniCard
                  icon={<IconBattery size={26} />}
                  preTitle={t(
                    "pages.dashboardVehicles.vehicleDetails.sensor.batteryVoltage"
                  )}
                  title={
                    sensorBatteryLevel
                      ? `${convertFromVoltageToBatteryLevel(
                          sensorBatteryLevel,
                          3.5,
                          2.5
                        )}${"%"}`
                      : "--"
                  }
                />
                <hr className="section-divider" />
                <div className="sensor_analysis_title">
                  {t(
                    "pages.dashboardVehicles.vehicleDetails.sensor.sensorAnalysis"
                  )}
                </div>{" "}
                <div className="sensor-dropdown-container">
                  <div className="sensor-dropdown">
                    <Dropdown
                      isClearable={false}
                      placeholder={t(
                        "pages.dashboardVehicles.vehicleDetails.sensor.selectSensor"
                      )}
                      value={sensorAnalysisSelected}
                      size="normal"
                      itemAttribute="label"
                      onChange={(val) => {
                        setSensorAnalysisSelected(val[0]);
                      }}
                      options={optionsSensor}
                    />
                    <div className="sensor-dropdown">
                      <Dropdown
                        isClearable={false}
                        placeholder={t(
                          "pages.dashboardVehicles.vehicleDetails.sensor.selectSensor"
                        )}
                        value={sensorPeriodSelected}
                        size="normal"
                        itemAttribute="label"
                        onChange={(val) => {
                          setSensorPeriodSelected(val[0]);
                        }}
                        options={optionPeriod}
                      />
                    </div>
                  </div>
                </div>
                <div className="container-cards">
                  <div className="container-card-temperature">
                    <div className="sensor-card-title">
                      {t(
                        "pages.dashboardVehicles.vehicleDetails.sensor.temperature"
                      )}
                    </div>
                    <div className="sensor-card">
                      <div className="sensor-data">
                        <div className="sensor-rows">
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.averageValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  (sensorAnalysisData?.avgTemperature <=
                                    sensorThresholdSelected?.minTemperature ||
                                    sensorAnalysisData?.avgTemperature >=
                                      sensorThresholdSelected?.maxTemperature)
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.avgTemperature
                                ? `${formatTemperature(
                                    sensorAnalysisData.avgTemperature
                                  )}`
                                : "--"}
                            </span>
                          </div>
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.maxValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  sensorAnalysisData?.maxTemperature >=
                                    sensorThresholdSelected?.maxTemperature
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.maxTemperature
                                ? formatTemperature(
                                    sensorAnalysisData?.maxTemperature
                                  )
                                : "--"}
                            </span>
                          </div>
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.minValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  sensorAnalysisData?.avgTemperature <=
                                    sensorThresholdSelected?.minTemperature
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.minTemperature
                                ? formatTemperature(
                                    sensorAnalysisData?.minTemperature
                                  )
                                : "--"}
                            </span>
                          </div>
                        </div>
                        <div className="sensor-row">
                          <IconTemperature size={36} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="container-card-humidity">
                    <div className="sensor-card-title">
                      {t(
                        "pages.dashboardVehicles.vehicleDetails.sensor.humidity"
                      )}
                    </div>
                    <div className="sensor-card">
                      <div className="sensor-data">
                        <div className="sensor-rows">
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.averageValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  (sensorAnalysisData?.avgHumidity <=
                                    sensorThresholdSelected?.minHumidity ||
                                    sensorAnalysisData?.avgHumidity >=
                                      sensorThresholdSelected?.maxHumidity)
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.avgHumidity
                                ? numberAnnotation(
                                    sensorAnalysisData?.avgHumidity,
                                    0,
                                    0
                                  ) + "%"
                                : "--"}
                            </span>
                          </div>
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.maxValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  sensorAnalysisData?.maxHumidity >=
                                    sensorThresholdSelected?.maxHumidity
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.maxHumidity
                                ? numberAnnotation(
                                    sensorAnalysisData?.maxHumidity,
                                    0,
                                    0
                                  ) + "%"
                                : "--"}
                            </span>
                          </div>
                          <div className="sensor-row">
                            <label className="sensor-data-text">
                              {t(
                                "pages.dashboardVehicles.vehicleDetails.sensor.minValue"
                              )}
                            </label>
                            <span
                              className="sensor-data-text-value"
                              style={{
                                color:
                                  sensorAnalysisData &&
                                  sensorThresholdSelected &&
                                  sensorAnalysisData?.minHumidity <=
                                    sensorThresholdSelected?.minHumidity
                                    ? "var(--global-colors-feedback-danger)"
                                    : "var(--global-colors-palette-black)",
                              }}
                            >
                              {sensorAnalysisData?.minHumidity
                                ? numberAnnotation(
                                    sensorAnalysisData?.minHumidity,
                                    0,
                                    0
                                  ) + "%"
                                : "--"}
                            </span>
                          </div>
                        </div>
                        <div className="sensor-row">
                          <IconHumidity size={36} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
            {/* Sensor */}
            {/* Refuels */}
            {
              <Card
                title={t("pages.dashboardVehicles.vehicleDetails.lastRefuels")}
                titleTooltip={t(
                  "pages.dashboardVehicles.vehicleDetails.lastRefuelsTooltip"
                )}
                linkAnchor={t("common.viewRefuels")}
                linkAction={() => {
                  window.open(
                    "/reports/refuelling-report/" +
                      vehicleId +
                      getQueryString({
                        startPeriod: dayjs(
                          new Date().setDate(new Date().getDate() - 6)
                        ).format(),
                        endPeriod: dayjs(new Date()).format(),
                      }),
                    "_blank",
                    "noopener,noreferrer"
                  );
                }}
              >
                {refuellingReportSliceStatus === "idle" &&
                refuellingReport &&
                refuellingReport.length > 0 ? (
                  _.orderBy(refuellingReport, ["date"], "desc")
                    .slice(0, 5)
                    .map((refuel, index) => (
                      <>
                        <Tooltip />
                        <SingleItemContainer
                          key={index}
                          element={refuel}
                          onClick={() => {
                            window.open(
                              `/dashboard/vehicles/location-history/stop/${refuel.routeId}` +
                                getQueryString({
                                  firstPointDate: [
                                    dayjs(
                                      new Date(refuel.date).setDate(
                                        new Date(refuel.date).getDate() - 1
                                      )
                                    ).format("YYYY/MM/DD HH:mm"),
                                    dayjs(
                                      new Date(refuel.date).setDate(
                                        new Date(refuel.date).getDate() + 1
                                      )
                                    ).format("YYYY/MM/DD HH:mm"),
                                  ],
                                  "vehicle.id": refuel.vehicleId ?? "",
                                  currentPos: refuel.date,
                                }),
                              "_blank",
                              "noopener,noreferrer"
                            );
                          }}
                        >
                          <span data-for="tooltip" data-tip={t("common.day")}>
                            <DataLabel
                              dataText={dayjs(refuel?.date).format("DD MMM")}
                              icon={<IconCalendar size={14} />}
                            />
                          </span>
                          <div
                            style={{
                              minWidth: "90px",
                            }}
                          >
                            <DataLabel
                              dataText={
                                preferencesContext.isMetric
                                  ? numberAnnotation(refuel?.refueledLevel) +
                                    " lt"
                                  : numberAnnotation(
                                      ltToGal(refuel?.refueledLevel)
                                    ) + " gal"
                              }
                              icon={<IconGasStation size={14} />}
                            />
                          </div>
                          <DataLabel
                            dataText={numberAnnotation(
                              convertEuroOnCurrency(
                                preferencesContext.currency,
                                refuel?.totalPrice
                              ),
                              2
                            )}
                            icon={
                              preferencesContext.currency === "EURO" ? (
                                <IconEuro size={14} />
                              ) : preferencesContext.currency === "DOLLAR" ? (
                                <IconDollar size={14} />
                              ) : (
                                <IconPound size={14} />
                              )
                            }
                          />
                          <div
                            data-for="tooltip"
                            data-tip={t("common.address")}
                          >
                            <DataLabel
                              dataText={refuel?.refuelAddress}
                              icon={<IconMarker size={14} />}
                            />
                          </div>
                        </SingleItemContainer>
                      </>
                    ))
                ) : (
                  <div className="last-tracks-notFound">
                    {t("pages.dashboardVehicles.vehicleDetails.noRefuels")}
                  </div>
                )}
              </Card>
            }
            {/* Utilization */}
            <VehicleUtilization
              percentage={utilizationData?.percentage}
              utilization={[
                {
                  title: t(
                    "pages.dashboardVehicles.vehicleDetails.drivingTime"
                  ),
                  data: utilizationData?.movingTime ?? "0",
                },
                {
                  title: t(
                    "pages.dashboardVehicles.vehicleDetails.drivingFuel"
                  ),
                  data: utilizationData?.movingConsumption ?? "0",
                },
                {
                  title: t("pages.dashboardVehicles.vehicleDetails.idleTime"),
                  data: utilizationData?.idleTime ?? "0",
                },
                {
                  title: t("pages.dashboardVehicles.vehicleDetails.idleFuel"),
                  data: utilizationData?.idleConsumption ?? "0",
                },
              ]}
              setPeriod={setUtilizationPeriod}
            />

            {/* Costs */}
            {!_.isEmpty(preferencesContext) && (
              <TimeSeriesChartCard
                graphTitle={{
                  first: {
                    title: t("card.fuel"),
                  },
                  second: {
                    title: t("card.maintenance"),
                  },
                }}
                chartDataSeries={[
                  {
                    name: "fuel",
                    data: expandPeriod(
                      vehicleFuelCosts.fuelsCost?.length > 0
                        ? vehicleFuelCosts.fuelsCost.map((item) => {
                            return {
                              ...item,
                              data: convertEuroOnCurrency(
                                preferencesContext.currency,
                                item?.data
                              ),
                            };
                          })
                        : [],
                      periodFuelCosts
                    ),
                    unitMeasure: currency,
                  },
                  {
                    name: "maintenance",
                    data: expandPeriod(
                      vehicleMaintenanceCosts.deadlineMaintenancePoint?.length >
                        0
                        ? vehicleMaintenanceCosts.deadlineMaintenancePoint.map(
                            (item) => {
                              return {
                                ...item,

                                data: convertEuroOnCurrency(
                                  preferencesContext.currency,
                                  item?.data
                                ),
                              };
                            }
                          )
                        : [],
                      periodFuelCosts
                    ),
                    unitMeasure: currency,
                  },
                ]}
                chartDateSeries={[
                  {
                    date:
                      vehicleFuelCosts.fuelsCost?.length > 0
                        ? vehicleFuelCosts.fuelsCost.map(
                            (fuelsCost) => fuelsCost.date
                          )
                        : [
                            periodFuelCosts.startPeriod,
                            periodFuelCosts.endPeriod,
                          ],
                  },
                  {
                    date:
                      vehicleMaintenanceCosts.deadlineMaintenancePoint?.length >
                      0
                        ? vehicleMaintenanceCosts.deadlineMaintenancePoint.map(
                            (deadlineMaintenancePoint) =>
                              deadlineMaintenancePoint.date
                          )
                        : [
                            periodFuelCosts.startPeriod,
                            periodFuelCosts.endPeriod,
                          ],
                  },
                ]}
                firstChartData={{
                  title: t("card.fuel"),
                  first: {
                    title: t("card.totalFuelCosts"),
                    data: vehicleFuelCosts.summary?.totalFuelCosts
                      ? numberAnnotation(
                          convertEuroOnCurrency(
                            preferencesContext.currency,
                            vehicleFuelCosts.summary.totalFuelCosts
                          )
                        ) +
                        " " +
                        currency
                      : `${t("common.na")}`,
                  },
                  second: reactDeployTarget
                    ? {
                        title: t("card.averageFuelCost"),
                        data: vehicleFuelCosts.summary?.avgFuelCosts
                          ? numberAnnotation(
                              convertEuroOnCurrency(
                                preferencesContext.currency,
                                vehicleFuelCosts.summary.avgFuelCosts
                              )
                            ) +
                            " " +
                            currencyFractionalMetricUom
                          : `${t("common.na")}`,
                      }
                    : undefined,
                }}
                secondChartData={{
                  title: t("card.maintenance"),
                  first: {
                    title: t("card.totalMaintenanceCosts"),
                    data:
                      vehicleMaintenanceCosts.summary?.totalDeadlineCosts &&
                      !isNaN(
                        vehicleMaintenanceCosts.summary?.totalDeadlineCosts
                      )
                        ? numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              vehicleMaintenanceCosts.summary.totalDeadlineCosts
                            )
                          ) +
                          " " +
                          currency
                        : `${t("common.na")}`,
                  },
                  second: {
                    title: t("card.averageMaintenanceCost"),
                    data:
                      vehicleMaintenanceCosts.summary?.avgDeadlineCosts &&
                      !isNaN(vehicleMaintenanceCosts.summary?.avgDeadlineCosts)
                        ? numberAnnotation(
                            convertEuroOnCurrency(
                              preferencesContext.currency,
                              vehicleMaintenanceCosts.summary.avgDeadlineCosts
                            )
                          ) +
                          " " +
                          currency
                        : `${t("common.na")}`,
                  },
                }}
                setPeriod={setPeriodFuelCosts}
                title={t("pages.dashboardVehicles.vehicleDetails.costs")}
                linkAnchor={
                  reactDeployTarget
                    ? t("pages.dashboardVehicles.vehicleDetails.viewFuelCosts")
                    : ""
                }
                linkAction={() => {
                  console.log("Click On Card");
                }}
                hasDropdown={true}
                status={
                  vehicleFuelCostsSliceStatus &&
                  vehicleMaintenanceCostsSliceStatus
                }
              />
            )}
            {/* Maintenances */}
            {deadlinesSliceStatus === "idle" && (
              <Card
                title={t("pages.dashboardVehicles.vehicleDetails.maintenance")}
                linkAction={() => {
                  window
                    .open(
                      `/dashboard/maintenance?status=SCHEDULED&status=IN_PROGRESS&status=EXPIRED&status=EXPIRING&vehicle.alias=${vehicle.alias}&vehicle.plate=${vehicle.plate}&type=MAINTENANCE_CAR_SERVICE&type=MAINTENANCE_INSPECTION&type=MAINTENANCE_CAR_BODYWORK&type=MAINTENANCE_EXTRAORDINARY&type=MAINTENANCE_ORDINARY&type=MAINTENANCE_TIRES&type=MAINTENANCE_CUSTOM`,
                      "_blank"
                    )
                    ?.focus();
                }}
                linkAnchor={t("common.viewAll")}
              >
                {dataTextsDeadlines.length
                  ? returnSinglecontainer(dataTextsDeadlines, true)
                  : null}
              </Card>
            )}
            {/* Deadlines */}
            {deadlinesSliceStatus === "idle" && (
              <Card
                title={t("pages.dashboardVehicles.vehicleDetails.deadlines")}
                linkAction={() => {
                  window
                    .open(
                      `/dashboard/maintenance?status=SCHEDULED&status=IN_PROGRESS&status=EXPIRED&status=EXPIRING&vehicle.alias=${vehicle.alias}&vehicle.plate=${vehicle.plate}&type=INSURANCE&type=TAXES&type=REVISION&type=CUSTOM`,
                      "_blank"
                    )
                    ?.focus();
                }}
                linkAnchor={t("common.viewAll")}
              >
                {dataTextsDeadlines.length
                  ? returnSinglecontainer(dataTextsDeadlines, false)
                  : null}
              </Card>
            )}
          </div>
        </div>
      </PageContent>
      <ModalWrapper
        open={viewModalAction}
        closeAction={() => setViewModalAction(false)}
      >
        <ModalBody
          title={titleModal}
          desc={descriptionModal}
          isScrollable={false}
        />
        <ModalFooter
          primaryAction={() => {
            if (vehicle.device) {
              store.dispatch(
                createCommandRequestAsync({
                  deviceId: vehicle.device,
                  command: commandName,
                  type: newCommandType,
                })
              );
            }
            setViewModalAction(false);
          }}
          primaryLabel={t("common.confirm")}
          disablePrimaryButton={isDisableConfirmationModal}
          secondaryAction={() => setViewModalAction(false)}
          secondaryLabel={t("common.cancel")}
        />
      </ModalWrapper>
    </>
  );
  //#endregion Render
};
